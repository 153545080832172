import React, { useState, useEffect } from 'react';
import { useThree } from '@react-three/fiber';
import { a, useSpring } from '@react-spring/three';
import { useDrag } from '@use-gesture/react';
import { carousel3dHelper } from 'core/utilities';
import { Card3d } from './Card3d';


export const Scene: React.FunctionComponent = () =>
{
    const { size, get, camera } = useThree();
    
    const [ sceneRotationY, setSceneRotationY ] = useState<number>(0);

    const [ canvasWidth, setCanvasWidth ] = useState<number>( size.width );

    const {
        IMAGES,
        RESPONSIVENESS,
        cardsAngles,
        radius,
        cameraDistance,
        width
    } = carousel3dHelper();

    camera.position.z = cameraDistance;

    const { rotationY } = useSpring({
        config: { mass: 5, tension: 170, friction: 150, precision: 0.0001 },
        rotationY: sceneRotationY 
    });
    
    const bind = useDrag( ( { delta: [ dx ] } ) =>
    {
        setSceneRotationY( sceneRotationY + ( dx / canvasWidth ) * RESPONSIVENESS );
    });

    const resizingCanvas = () =>
    {
        const { size } = get();
        setCanvasWidth( size.width );
    };

    useEffect( () =>
    {
        window.addEventListener( 'resize', resizingCanvas );

    }, [ canvasWidth ] );

    return (
        <>
            {/*@ts-ignore*/ }
            <a.group
                {...bind()}
                rotation-y={rotationY}
            >
                {IMAGES.map((image: string, index: number) => (
                    <Card3d
                        key={'3dCard' + index}
                        image={image}
                        angle={cardsAngles[ index ]}
                        radius={radius}
                        width={width}
                        height={width}
                    />
                ))}
            </a.group>
        </>
    );
};
