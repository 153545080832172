import React from 'react';
import styled from 'styled-components';
import { Palette, MediaQuery } from 'core/config';
import IMAGES from 'assets/images';

interface CardProps
{
    image: string;
    text: string;
}

export const Card: React.FunctionComponent<CardProps> = ( { image, text } ) =>
{

    return (
        <Container src={IMAGES.purpleRectangleEdgeCut}>
            <Image src={image} />
            <Text>{text}</Text>
        </Container>

    );
};

const Container = styled.div<{ src?: string }>`
    width: 160px;
    height: 189px;

    background: url(${ ( { src } ) => src });
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    margin-bottom: 15px;

    @media ${MediaQuery.laptopS} {
        width: 260px;
        height: 300px;
        margin-bottom: 33px;
    }

    @media ${MediaQuery.laptopM} {
        width: 266px;
        height: 306px;
        margin-bottom: 33px;
    }
`;

const Image = styled.img<{ src: string }>`
    z-index: 1;
    width:  68px;
    height: auto;

    @media ${MediaQuery.laptopS} {
        width: 98px;
    }
`;

const Text = styled.p`
    width: 141px;

    text-align: center;
    font-family: 'Poppins-Light';
    font-size: 16px;
    font-weight: 600;
    color: ${Palette.WHITE};
    
    @media ${MediaQuery.laptopS} {
        font-size: 20px;
        width: 162px;
    }
`;

