import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Palette, MediaQuery } from 'core/config';
import { showCaseImages } from '../data/showCaseImages.json';
import IMAGES from 'assets/images';

interface HeroProps {
  navigateToSection?: (index: number) => void;
}

export const Hero: React.FunctionComponent<HeroProps> = ({
    navigateToSection = () => null,
}) => 
{
    return (
        <Container>
            <InnerContainer className="fade-in">
                <Content>
                    <TextContent>
                        <Title>{'We Create Impactful\nExperiences'}</Title>
                        <Subtitle>
                            {
                                'Designing and developing digital products and experiences for innovative companies, globally.'
                            }
                        </Subtitle>
                    </TextContent>

                    <ShowcaseContent>
                        {showCaseImages.map((item, index) => (
                            <ShowcaseImage
                                key={item.id}
                                src={IMAGES[item.imageKey]}
                                alt={`Showcase image ${index + 1}`}
                            />
                        ))}
                    </ShowcaseContent>

                    <Button onClick={() => navigateToSection(1)}>See Our Work</Button>
                </Content>
            </InnerContainer>
        </Container>
    );
};

const fadeIn = keyframes`
    0% { opacity: 0 } 
    100% { opacity: 1 } 
`;

const Container = styled.div`
  z-index: 5000;
  display: flex;
  margin: 77px 16px 0 16px;

  /* background-color: white; */
  overflow: hidden;

  .fade-in {
    animation: ${fadeIn} 0.5s ease-in;
  }

  @media ${MediaQuery.tablet} {
    min-width: 100vw;
    min-height: 90vh;
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;

  width: 100%;
  height: 100%;

  .fade-in-sphere {
    transition-duration: 0.7s;
    opacity: 1;
  }
  .fade-out-sphere {
    opacity: 0;
  }

  @media ${MediaQuery.tablet} {
    flex-direction: row;
    padding: 3rem 5rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextContent = styled.div`
  padding-top: 100px;
  @media ${MediaQuery.tablet} {
    max-width: 45vh;
    padding-top: 0;
    margin-left: 5%;
  }

  @media ${MediaQuery.laptopM} {
    max-width: 70vh;
  }
`;

const Title = styled.h1`
  width: 100%;

  color: ${Palette.BLACK};

  font-size: calc(1.325em + 4vw);
  line-height: 1.15em;
  white-space: pre-line;

  @media ${MediaQuery.tablet} {
    width: 40vw;
    font-size: 2.75em;
  }
  @media ${MediaQuery.laptopS} {
    font-size: 3.375em;
  }
  @media ${MediaQuery.desktopS} {
    width: 100%;
    font-size: 4em;
  }
`;

const Subtitle = styled.h4`
  width: 100%;

  color: ${Palette.BLACK};
  font-family: "Poppins-Light";

  font-size: 16px;
  line-height: 180%;
  white-space: pre-line;
  padding-top: 24px;

  @media ${MediaQuery.tablet} {
    width: 40vw;
    font-size: 18px;
  }
  @media ${MediaQuery.laptopS} {
    width: 40vw;
    font-size: 20px;
    padding-top: 14px;
  }
  @media ${MediaQuery.desktopS} {
    width: 40vw;
    font-size: 22px;
    padding-top: 14px;
  }
`;

const ShowcaseContent = styled.div`
  max-width: 90vw;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  display: flex;
  gap: 48px;
  padding-top: 48px;
  padding-bottom: 48px;

  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbars for Firefox */
  scrollbar-width: none;

  @media ${MediaQuery.tablet} {
    margin-left: 5%;
    flex-wrap: nowrap;
  }
`;

const ShowcaseImage = styled.img`
  width: 90px;
  height: 24px;
`;

const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
    -webkit-tap-highlight-color: transparent;

  width: 213px;

  border: 1px solid ${Palette.BLACK};
  background-color: ${Palette.BLACK};
  color: ${Palette.WHITE};

  font-family: "Poppins-Light";
  font-size: 12px;

  cursor: pointer;
  transition: opacity 1s ease-in-out;
  border-radius: 50px;
  padding: 14px 48px;

  :hover {
    background-color: ${Palette.WHITE};
    color: ${Palette.BLACK};
  }

  @media ${MediaQuery.tablet} {
    font-family: "Poppins-Regular";
    font-size: 17px;
    margin-left: 5%;
  }
`;
