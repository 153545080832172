import IMAGES from 'assets/images';
import { useHistory } from 'react-router';
import { MediaQuery, Palette } from 'core/config';
import React from 'react';
import styled from 'styled-components';

const FeaturedAnimatedCard: React.FC<any> = ({ item }) => 
{
    const history = useHistory();

    const onCardClick = (link: string) => 
    {
        if (link.includes('http')) 
        {
            window.open(link, '_blank');
        }
        else 
        {
            history.push(link);
        }
    };

    return (
        <CardWrapper>
            <CardContainer onClick={() => onCardClick(item.url)}>
                <CardImage src={IMAGES[item.imageKey]} />
                <CardContent>
                    <StackContainer>
                        {item.techStack.map((item: string, index: number) => (
                            <TeckStackItem key={item + index}> {item}</TeckStackItem>
                        ))}
                    </StackContainer>
                    <FeaturedItemTitle>{item.title}</FeaturedItemTitle>
                    <FeaturedItemDesc>{item.desc}</FeaturedItemDesc>
                </CardContent>
            </CardContainer>
        </CardWrapper>
    );
};

const CardWrapper = styled.div`
overflow: hidden;
`;

const CardContainer = styled.div`
  display: none;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  cursor: none;
  
  &::after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    border: 0.5px solid black;
    border-radius: 20px;
    pointer-events: none;
  }

  @media ${MediaQuery.laptopS} {
    display: flex;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 20px;
  transition: transform 0.3s ease-in-out;
  z-index: 1;
  object-fit: cover;

  ${CardContainer}:hover & {
    transform: translateY(55%) scale(0.9);
    transform-origin: center center;
  }
`;

const CardContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 32px;
  box-sizing: border-box;
  z-index: -1;
`;

const StackContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;

const TeckStackItem = styled.p`
  color: ${Palette.BLACK};
  font-family: "Poppins-Light";
  font-size: 14px;
`;

const FeaturedItemTitle = styled.h4`
  color: ${Palette.BLACK};
  font-family: "Poppins-Regular";
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const FeaturedItemDesc = styled.p`
  color: ${Palette.BLACK};
  font-family: "Poppins-Light";
  font-size: 18px;
`;

export default FeaturedAnimatedCard;
