import React from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import { MediaQuery } from 'core/config';

interface Props
{
    gridArea? : string;
}

export const HeroNavbar: React.FunctionComponent<Props> = ( { gridArea }) =>
{

    return (
        <Container gridArea={ gridArea }>
            <StyledLink activeClass="active" to="lab-hero" spy={ true } smooth={ true }  duration={ 500 } >
                <Title>Home</Title>
                <VerLineImg/>
            </StyledLink>
            <StyledLink activeClass="active" to="lab-services" spy={ true } smooth={ true }  duration={ 500 } >
                <Title>Services</Title>
                <VerLineImg />
            </StyledLink>
            <StyledLink activeClass="active" to="lab-roadmap" spy={ true } smooth={ true }  duration={ 800 } >
                <Title>Roadmap</Title>
                <VerLineImg />
            </StyledLink>
            <StyledLink activeClass="active" to="lab-intern" spy={ true } smooth={ true }  duration={ 1000 } >
                <Title>Intern With Us</Title>
                <VerLineImg />
            </StyledLink>
            <StyledLink activeClass="active" to="contact-us" spy={ true } smooth={ true }  duration={ 1000 } >
                <Title>Contact Us</Title>
                <VerLineImg />
            </StyledLink>
        </Container>
    );
};


const Container = styled.div<{ gridArea?: string }>`
    display: none;
    gap: 40px;
    margin-right: 16px;

    @media ${MediaQuery.tablet} {
        z-index: 10000;
        grid-area: ${ ( { gridArea } ) => gridArea};

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    @media ${MediaQuery.laptopS} {
        margin-right: 40px;
    }

    @media ${MediaQuery.laptopM} {
        gap: 100px;
        margin-right: 100px;
    }

    @media ${MediaQuery.desktopS} {
        gap: 110px;
        margin-right: 110px;
    }
`;

const StyledLink = styled( Link )`
    display: none;

    @media ${MediaQuery.tablet} {
        display: flex;
        flex-direction: column;
        align-items: center;

        cursor: grab;

        &:hover div {
            visibility: visible;
        }
    }
`;

const Title = styled.p`
    font-family: 'Poppins-Regular';
    font-size: 17px;
    text-align: center;
    color: white;
`;

const VerLineImg = styled.div`
    height: 4px;
    width: 120%;

    background: #c0a8f0;
    filter: drop-shadow(0 0 0.2rem #c0a8f0);

    border-radius: 2px;

    margin-top: 7px;

    visibility: hidden;
`;

