import React from 'react';
import styled from 'styled-components';
import { Palette, MediaQuery } from 'core/config';
import { Card } from './Card';
import IMAGES from 'assets/images';

export const ServicesCards: React.FunctionComponent = () =>
{

    return (
        <Container>
            <FirstColmContainer>
                <Card image={IMAGES.web3Sphere} text={'Web3 Development'} />
                <Card image={IMAGES.digitalExperience} text={'AR & Custom Digital Experiences'} />
            </FirstColmContainer>
            <SecondColmContainer>
                <Card image={IMAGES.vcConnection} text={'VC Connections'} />
                <Card image={IMAGES.web2Dev} text={'Web2 Development'} />
            </SecondColmContainer>
        </Container>

    );
};

const Container = styled.div`
    background-color: ${Palette.BLACK};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 17px;
    margin-right: 17px;

    @media ${MediaQuery.tablet} {
        justify-content: center;
    }

    @media ${MediaQuery.laptopS} {
        justify-content: space-between;
        padding: 0;
        margin-top: 100px;
        margin-left: 0;
    }

    @media ${MediaQuery.laptopM} {
        padding: 0;
        margin-top: 100px;
        margin-left: 15vw;
    }
`;

const FirstColmContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
`;

const SecondColmContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 100px;

    @media ${MediaQuery.tablet} {
        margin-left: 33px;
    }

    @media ${MediaQuery.laptopS} {
        margin-left: 17px;
    }

    @media ${MediaQuery.laptopM} {
        margin-top: 165px;
        margin-left: 33px;
    }
`;


