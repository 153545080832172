import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import IMAGES from 'assets/images';
import { MediaQuery, NoSelect, Palette } from 'core/config';
import { useAppContext } from 'core/context';
import { useScroll } from 'view/hooks';
import fontColorContrast from 'font-color-contrast';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductsMenu from '../ProductsMenu/ProductsMenu';
import { HeroNavbar } from 'view/pages/Lab/components';

export enum SCROLL_BEHAVIOR {
  HIDE_ON_SCROLL = 'hide-on-scroll',
  OPAQUE_AFTER_SCROLL = 'opaque-after-scroll',
  ALWAYS_OPAQUE = 'always-opaque',
}

type ScrollBehaviorData = {
  desktop?: SCROLL_BEHAVIOR;
  mobile?: SCROLL_BEHAVIOR;
};

interface Props {
  scrollBehavior: ScrollBehaviorData;
  scrollElement?: string; // The id of the element that is used for scrolling (for desktop only, mobile will always use window)
  onContactNav?: () => void;
  path?: string;
}

const Navbar: React.FunctionComponent<Props> = ({
    scrollBehavior,
    scrollElement,
    onContactNav,
    path = '',
}) => 
{
    const scrollY = useScroll(scrollElement);
    const history = useHistory();
    const { isDesktop, scrollbarWidth, backgroundColor, isLabHeroNavbarIntersected } = useAppContext();
    const { navbarIsExpanded, setNavbarIsExpanded } = useAppContext();
    const [ navbarIsVisible, setNavbarIsVisible ] = useState<boolean>(true);
    const [ navbarIsOpaque, setNavbarIsOpaque ] = useState<boolean>(
        scrollBehavior.mobile === SCROLL_BEHAVIOR.ALWAYS_OPAQUE
    );
    const [ anchorElProducts, setAnchorElProducts ] = useState<null | HTMLElement>(
        null
    );
    /* eslint-disable  @typescript-eslint/no-unused-vars*/
    const [ isLabPage, setIsLabPage ] = useState<boolean>(path === 'lab');
    const [ customBgColor, setCustomBgColor ] = useState<string>('');
    const [ customFgColor, setCustomFgColor ] = useState<string>('');

    useEffect(() => 
    {
        if (path === 'color-contrast-checker') 
        {
            setCustomBgColor(backgroundColor);
            setCustomFgColor(fontColorContrast(backgroundColor));
        }
    }, [ backgroundColor ]);

    useEffect(() => 
    {
        if (
            !isDesktop &&
      scrollBehavior.mobile === SCROLL_BEHAVIOR.OPAQUE_AFTER_SCROLL
        ) 
        {
            if (scrollY && scrollY > 0) 
            {
                setNavbarIsOpaque(true);
            }
            else 
            {
                setNavbarIsOpaque(false);
            }
        }
        if (
            isDesktop &&
      scrollBehavior.desktop === SCROLL_BEHAVIOR.HIDE_ON_SCROLL
        ) 
        {
            if (scrollY && scrollY > 77) 
            {
                setNavbarIsVisible(false);
            }
            else 
            {
                setNavbarIsVisible(true);
            }
        }
        if (!isDesktop) 
        {
            if (scrollY && scrollY > 0) 
            {
                setNavbarIsVisible(true);
            }
            setAnchorElProducts(null);
        }
    }, [ scrollY, isDesktop ]);

    const handleLinkClick = useCallback(
        (href: string) => () => 
        {
            history.push(href);
            setNavbarIsExpanded(false);
            resetScrollPosition();
        },
        []
    );

    const handleContactClick = () => 
    {
        const el = scrollElement
            ? document.getElementById(scrollElement)
            : document.querySelector('body');
        if (scrollElement) 
        {
            //NOTE: BlogLayout uses container and not window for scrolling because of parallax
            el?.scrollTo({
                left: 0,
                top: el.scrollHeight,
                behavior: 'smooth',
            });
        }
        else 
        {
            //NOTE: All other layouts use window for scrolling
            window?.scrollTo({
                left: 0,
                top: el?.scrollHeight,
                behavior: 'smooth',
            });
        }
        setNavbarIsExpanded(false);
    };

    const resetScrollPosition = () => 
    {
        const container = document.getElementById('container');
        container?.scrollTo({ left: 0, top: 0 });
        window?.scrollTo({ left: 0, top: 0 });
    };

    const menuItems = [
        {
            label: 'Work',
            link: '/work',
            hidden: process.env.REACT_APP_WORK_DISABLED,
        },
        {
            label: 'Labs',
            link: '/lab',
            hidden: process.env.REACT_APP_LABS_DISABLED,
        },
        {
            label: 'Press',
            link: '/press',
            hidden: process.env.REACT_APP_PRESS_DISABLED,
        },
        {
            label: 'Team',
            link: '/team',
            hidden: process.env.REACT_APP_TEAM_DISABLED,
        },
        {
            label: 'Blog',
            link: '/blog',
            hidden: process.env.REACT_APP_BLOG_DISABLED,
        },
    ];

    const handleProductsMenuClick = (event: React.MouseEvent<HTMLElement>) => 
    {
        if (isDesktop) 
        {
            setAnchorElProducts(event.currentTarget);
        }
        else 
        {
            const el = scrollElement
                ? document.getElementById(scrollElement)
                : document.querySelector('body');

            if (scrollElement) 
            {
                //NOTE: BlogLayout uses container and not window for scrolling because of parallax
                el?.scrollTo({
                    left: 0,
                    top: el.scrollHeight + 300,
                    behavior: 'smooth',
                });
            }
            else 
            {
                //NOTE: All other layouts use window for scrolling
                window?.scrollTo({
                    left: 0,
                    top: el?.scrollHeight ? el?.scrollHeight + 300 : el?.scrollHeight,
                    behavior: 'smooth',
                });
            }
            setNavbarIsExpanded(false);
        }
    };

    const handleCloseProductsMenu = () => 
    {
        setAnchorElProducts(null);
    };

    return (
        <Container
            customBgColor={customBgColor}
            isLabPage={isLabPage}
            scrollBehavior={scrollBehavior}
            scrollbarWidth={scrollbarWidth}
            navbarIsVisible={navbarIsVisible}
            isExpanded={navbarIsExpanded}
        >
            <InnerContainer
                customBgColor={customBgColor}
                isLabPage={isLabPage}
                navbarIsOpaque={navbarIsOpaque}
                isExpanded={navbarIsExpanded}
            >
                <Logo
                    onClick={handleLinkClick('/')}
                    src={
                        isLabPage || customFgColor.toUpperCase() === Palette.WHITE
                            ? IMAGES.speerLogoWhite
                            : IMAGES.speerLogo
                    }
                    alt="Speer Logo"
                />
                {isLabPage ? (
                    <HeaderContainer>
                        <HeroNavbarWrapper showHeroNavbar={isLabHeroNavbarIntersected ?? false}>
                            <HeroNavbar />
                        </HeroNavbarWrapper>
                        <MainBtnContainer onClick={handleLinkClick('/')}>
                            <Title>Visit Main Site</Title>
                            <Arrow src={IMAGES.midRightArrowIcon} alt="Right Arrow" />
                        </MainBtnContainer>
                    </HeaderContainer>
                ) : (
                    <Hamburger
                        onClick={() => setNavbarIsExpanded(!navbarIsExpanded)}
                        isExpanded={navbarIsExpanded}
                    >
                        <Top customFgColor={customFgColor} />
                        <Bottom customFgColor={customFgColor} />
                    </Hamburger>
                )}
            </InnerContainer>
            {isLabPage ? (
                <></>
            ) : (
                <NavLinks customBgColor={customBgColor} isExpanded={navbarIsExpanded}>
                    {menuItems.map((item, index) =>
                        item.hidden ? null : (
                            <NavLink
                                key={index}
                                onClick={handleLinkClick(item.link)}
                                customFgColor={customFgColor}
                                isMenuOpened={Boolean(anchorElProducts)}
                            >
                                {item.label}
                            </NavLink>
                        )
                    )}
                    <NavLink
                        onClick={onContactNav ? onContactNav : handleContactClick}
                        customFgColor={customFgColor}
                        isMenuOpened={Boolean(anchorElProducts)}
                    >
            Contact
                    </NavLink>
                    <ExpandableNavLink
                        onClick={handleProductsMenuClick}
                        customFgColor={customFgColor}
                    >
                        <Menu>
                            <div
                                style={{
                                    flex: '1 1 auto',
                                    fontFamily: 'inherit',
                                    fontSize: 'inherit',
                                }}
                            >
                                <span
                                    style={{
                                        display: 'block',
                                        fontFamily: 'inherit',
                                        fontSize: 'inherit',
                                    }}
                                    >
                    Our Products
                                </span>
                            </div>
                            {isDesktop && <ExpandMoreIcon sx={{ marginLeft: 1 }} />}
                        </Menu>
                    </ExpandableNavLink>
                    <ProductsMenu
                        anchorEl={anchorElProducts}
                        onClose={handleCloseProductsMenu}
                    />
                    <NavItem>
                        <WorkWithUsButton onClick={handleLinkClick('/work')}>
              Work With Us
                        </WorkWithUsButton>
                    </NavItem>
                    <NavItem>
                        <HorizontalRule customFgColor={customFgColor} />
                    </NavItem>
                    <NavItem>
                        <SocialLinks customFgColor={customFgColor}>
                            <SocialLink customFgColor={customFgColor}>
                                <a
                                    href="https://www.linkedin.com/company/reframethefuture"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                  LinkedIn
                                </a>
                            </SocialLink>
                            <SocialLink customFgColor={customFgColor}>
                                <a
                                    href="https://www.instagram.com/speertech/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                  Instagram
                                </a>
                            </SocialLink>
                            <SocialLink customFgColor={customFgColor}>
                                <a
                                    href="https://facebook.com/speertech"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                  Facebook
                                </a>
                            </SocialLink>
                            <SocialLink customFgColor={customFgColor}>
                                <a
                                    href="https://www.tiktok.com/@techspeer?lang=en"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                  Tiktok
                                </a>
                            </SocialLink>
                        </SocialLinks>
                    </NavItem>
                </NavLinks>
            )}
        </Container>
    );
};

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    // align-items: center;

    position: absolute;
    z-index: 9000;
    top: 28px;
    right: 16px;

    @media ${MediaQuery.laptopS} {
        right: 80px;
    }
`;

const MainBtnContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  min-width: 155px;

  cursor: pointer;

  :hover {
    h6,
    img {
      transform: scale(1.1);
    }
  }
`;

const Arrow = styled.img`
  width: 25px;
  margin-left: 20px;
`;

const Title = styled.h6`
  color: ${Palette.WHITE};

  text-align: center;
  font-size: 16px;
  font-weight: 500;
`;

const Container = styled.header<{
  scrollBehavior: ScrollBehaviorData;
  scrollbarWidth: number;
  navbarIsVisible: boolean;
  isExpanded: boolean;
  isLabPage: boolean;
  customBgColor: string;
}>`
  position: fixed;
  z-index: 10000;
  top: 0;

  background-color: ${({ isLabPage, customBgColor }) =>
        customBgColor ? customBgColor : isLabPage ? Palette.BLACK : Palette.WHITE};

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 77px;

  transition: background-color 0.2s ease-in-out;

  ${({ isExpanded }) =>
        isExpanded &&
    `
        height: 100%;
    `}

  ${({ navbarIsVisible }) =>
        !navbarIsVisible &&
    `
        visibility: visible;
        opacity: 0;
        height: 0;

        > div {
            height: 0;
        }
    `}

    ${({ scrollBehavior, scrollbarWidth }) =>
        scrollBehavior.mobile === SCROLL_BEHAVIOR.OPAQUE_AFTER_SCROLL &&
    `
        width: calc(100vw - ${scrollbarWidth}px);
    `}

    ${NoSelect}
`;

const InnerContainer = styled.div<{
  navbarIsOpaque?: boolean;
  isExpanded?: boolean;
  isLabPage: boolean;
  customBgColor: string;
}>`
  z-index: 9000;

  width: 100%;
  min-height: 77px;

  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease-in-out;

  ${({ isExpanded }) =>
        !isExpanded &&
    `
        transition: all 0.25s ease-in;
        transition-property: box-shadow, background-color;
    `}

  ${({ navbarIsOpaque, isExpanded, isLabPage, customBgColor }) =>
        navbarIsOpaque &&
    !isExpanded &&
    `
        box-shadow: 0px 3px 10px #0000001A;
        background-color: ${
    customBgColor
        ? customBgColor
        : isLabPage
            ? Palette.BLACK
            : Palette.WHITE
};
    `}

    ${({ navbarIsOpaque, isExpanded, isLabPage, customBgColor }) =>
        (navbarIsOpaque || isExpanded) &&
    `
        background-color: ${
    customBgColor
        ? customBgColor
        : isLabPage
            ? Palette.BLACK
            : Palette.WHITE
};
    `}

    @media ${MediaQuery.laptopS} {
    min-height: 0;

    box-shadow: none;
  }
`;

const Logo = styled.img`
  position: absolute;
  z-index: 9000;
  top: 28px;
  left: 24px;

  opacity: 1;
  transform: scale(1.5);
  cursor: pointer;
-webkit-tap-highlight-color: transparent;

  @media ${MediaQuery.laptopS} {
    left: 80px;
    transform: scale(1);
  }
`;

const NavLinks = styled.ul<{ isExpanded: boolean; customBgColor: string }>`
  z-index: 9000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  height: 100%;
  padding: 0;

  background-color: ${({ customBgColor }) =>
        customBgColor ? customBgColor : Palette.WHITE};

  font-family: "Poppins-Regular";
  list-style-type: none;

  visibility: hidden;
  opacity: 0;

  ${({ isExpanded }) =>
        isExpanded &&
    `
        padding: calc(-35px + 15.3941vh) 16px 0 16px;

        visibility: visible;
        opacity: 1;

        > li, button, div, ul {
            visibility: visible;
            opacity: 1;
        }
    `}

  @media ${MediaQuery.laptopS} {
    position: absolute;
    z-index: 9000;
    top: 0px;
    right: 90px;

    display: flex;
    flex-direction: row;

    background: none;

    padding: 0;

    visibility: visible;
    opacity: 1;
  }
`;

const NavLink = styled.li<{ customFgColor: string, isMenuOpened: boolean }>`
  margin-bottom: calc(-35px + 10.75vh);

  font-family: "Poppins-Medium";
  font-size: 1.25em;
  text-transform: uppercase;

  opacity: 0.3;
  cursor: pointer;

  transition: opacity 0.25s ease-in;

  color: ${({ customFgColor }) =>
        customFgColor ? customFgColor : Palette.BLACK};

  opacity: ${({ isMenuOpened }) => isMenuOpened ? 0.3 : 1};

  ${NavLinks}:has(:hover) &:not(:hover) {
    opacity: 0.3;
  }

  &:hover {
    opacity: 1;
  }

  @media ${MediaQuery.laptopS} {
    margin: 0 0 0 3.3125em;
    font-family: "Poppins-Regular";
    font-size: 1.0625em;
    text-transform: none;
  }
`;

const ExpandableNavLink = styled.li<{ customFgColor: string }>`
  display: flex;
  white-space: nowrap;
  margin-bottom: calc(-35px + 10.75vh);

  font-family: "Poppins-Medium";
  font-size: 1.25em;
  text-transform: uppercase;

  opacity: 1;
  cursor: pointer;

  transition: opacity 0.25s ease-in;

  color: ${({ customFgColor }) =>
        customFgColor ? customFgColor : Palette.BLACK};

  @media ${MediaQuery.mobileS} {
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  @media ${MediaQuery.laptopS} {
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin: 0 0 0 3.3125em;
    padding-left: 0;
    font-family: "Poppins-Regular";
    font-size: 1.0625em;
    text-transform: none;
  }
`;

const Menu = styled.div`
  display: flex;
  white-space: nowrap;

  opacity: 1;
  transition: opacity 0.25s ease-in;
  
  ${NavLinks}:has(:hover) &:not(:hover) {
    opacity: 0.3;
  }
`;

const NavItem = styled.li`
  width: 100%;
`;

const WorkWithUsButton = styled.button`
  display: block;
  margin: 7px auto 0 auto;
  padding: 1em 2em;

  background: ${Palette.NAVAJO_WHITE} 0% 0% no-repeat padding-box;

  border: 0;
  border-radius: 3.125em;

  font-family: "Poppins-Regular";
  font-size: 0.75em;
  text-transform: uppercase;

  opacity: 0;
  cursor: pointer;

  transition: all 0.25s ease-in;

  @media ${MediaQuery.laptopS} {
    display: none;
  }
`;

const HorizontalRule = styled.div<{ customFgColor: string }>`
  width: 100%;
  height: 1px;

  background-color: ${({ customFgColor }) =>
        customFgColor ? customFgColor : Palette.DOVE_GRAY};
  margin: calc(-60px + 20vh) 0px 0;

  opacity: 0;

  transition: all 0.25s ease-in;
`;

const SocialLinks = styled.ul<{ customFgColor: string }>`
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;

  width: 100%;
  margin-top: calc(-35px + 10.75vh);

  color: ${({ customFgColor }) =>
        customFgColor ? customFgColor : Palette.BLACK};

  opacity: 0;

  transition: all 0.25s ease-in;

  @media ${MediaQuery.laptopS} {
    display: none;
  }
`;

const SocialLink = styled.li<{ customFgColor: string }>`
  font-family: "Poppins-Light";
  font-size: 0.75em;
  text-decoration: underline;

  > a {
    color: ${({ customFgColor }) =>
        customFgColor ? customFgColor : Palette.BLACK};
  }

  @media ${MediaQuery.laptopS} {
    display: none;
  }
`;

const HeroNavbarWrapper = styled.div<{ showHeroNavbar: boolean }>`
    visibility: hidden;

    @media ${MediaQuery.laptopS} {
        visibility: ${({ showHeroNavbar }) => showHeroNavbar ? 'visible' : 'hidden'};
    }
`;

const Hamburger = styled.div<{ isExpanded: boolean }>`
  position: absolute;
  z-index: 9000;
  top: 35px;
  right: 12px;
  -webkit-tap-highlight-color: transparent;

  cursor: pointer;

  ${({ isExpanded }) =>
        isExpanded &&
    `
        ${Top} {
            transform: rotate(45deg);
            height: 2px;
        }
        ${Bottom} {
            transform: translateX(0px) translateY(-6px) rotate(-45deg);
            height: 2px;
        }
    `}

  @media ${MediaQuery.laptopS} {
    display: none;
  }
`;

const Top = styled.div<{ customFgColor: string }>`
  width: 27px;
  height: 3px;

  margin-bottom: 4px;

  background-color: ${({ customFgColor }) =>
        customFgColor ? customFgColor : Palette.BLACK};

  transition: transform 0.25s;
`;

const Bottom = styled.div<{ customFgColor: string }>`
  width: 27px;
  height: 3px;

  background-color: ${({ customFgColor }) =>
        customFgColor ? customFgColor : Palette.BLACK};

  transition: transform 0.25s;
`;

export default Navbar;
