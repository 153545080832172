import React, { Suspense } from 'react';
import styled from 'styled-components';
import { Canvas } from '@react-three/fiber';
import { Scene } from './Scene';
import { MediaQuery } from 'core/config';
import * as THREE from 'three';

export const Carousel3d: React.FunctionComponent = () =>
{

    return (
        <CarouselBody>
            <ThreeCanvas
                mode='concurrent'
                camera={ {
                    position: [ 0, 3, 60 ]
                } }
                onCreated={ state =>
                {
                    state.gl.toneMapping = THREE.NoToneMapping;
                    state.gl.outputEncoding = THREE.sRGBEncoding;
                    state.gl.toneMappingExposure = 2;
                } }
            >
                <color attach='background' args={[ 'black' ]} />
                <ambientLight args={ [ 0x444444, 3.5 ] } />
                <Suspense fallback={null}>
                    <Scene />
                </Suspense>
            </ThreeCanvas>
        </CarouselBody>
    );
};

const ThreeCanvas = styled( Canvas )`
    canvas {
        touch-action: none;
    }
`;

const CarouselBody = styled.div`
	width: 100%;
	height: 250px;

    @media ${MediaQuery.tablet} {
        height: 350px;
    }

    @media ${MediaQuery.laptopS} {
        height: 450px;
    }

    @media ${MediaQuery.laptopM} {
        height: 560px;
    }

    @media ${MediaQuery.desktopS} {
        height: 620px;
    }
`;

