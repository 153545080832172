import * as THREE from 'three';
import React from 'react';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import
{
    onPointerOver,
    onPointerOut
} from 'core/utilities';

interface Card3dProps
{
    image: string;
    angle: number;
    radius: number;
    width: number;
    height: number;
}

export const Card3d: React.FunctionComponent<Card3dProps> = ({
    image,
    angle,
    radius,
    width,
    height
}) =>
{
    const texture = useLoader(TextureLoader, image) as THREE.Texture;

    if ( texture ) texture.encoding = THREE.sRGBEncoding;

    return (
        <group
            position={[ 0, 0, 0 ]}

            rotation={[ 0, angle, 0 ]}
        >
            <mesh
                position={[ 0, 0, radius ]}
                onPointerOver={onPointerOver}
                onPointerOut={onPointerOut}
            >
                <planeGeometry args={[ width, height, 3, 3 ]} />
                <meshStandardMaterial map={texture} side={THREE.DoubleSide} transparent={true}/>
            </mesh>
        </group>
    );
};
